import React, { useState, useEffect, useRef } from "react";
import logo from './logo.svg';
import './App.css';

function App() {

  const myName = useRef(null);
  const [theMessage, setTheMessage] = useState('What is your name?');

  const [myNameWas, setMyNameWas] = useState('null');

  
  useEffect(() => {    
    setMyNameWas(localStorage.getItem('myNameWas'));
  }, []);
  

  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          {theMessage}
        </p>
        <div>
          <input type="text" ref={myName} name="myName" id="myName" />
        </div>
        <div>

          <button onClick={() => {
            setTheMessage(myName.current.value);
            localStorage.setItem('myNameWas', myName.current.value);
          }}>Click Me!</button>

        </div>
        <p>
          {myNameWas}
        </p>
      </header>
    </div>
  );
}

export default App;
